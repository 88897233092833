let recaptchaJsScript = null;

const removeRecaptchaJsScript = () => {
  if (recaptchaJsScript && recaptchaJsScript.parentNode) {
    recaptchaJsScript.parentNode.removeChild(recaptchaJsScript);
  }
};

export const removeRecaptchaBadge = () => {
  const googleBadge = document.querySelector('.grecaptcha-badge');
  if (googleBadge) {
    const goggleBadgeContainer = googleBadge.parentNode;
    goggleBadgeContainer.parentNode.removeChild(goggleBadgeContainer);
  }
};

const resetRecaptchaJs = () => {
  removeRecaptchaBadge();
  removeRecaptchaJsScript();
  delete window.grecaptcha;
};

export const addRecaptchaJs = (storeConfiguration, callback) => {
  resetRecaptchaJs(storeConfiguration);
  recaptchaJsScript = document.createElement('script');
  recaptchaJsScript.src = storeConfiguration?.asam_recaptcha?.urls?.script_url;
  recaptchaJsScript.onload = callback;
  document.body.appendChild(recaptchaJsScript);
};

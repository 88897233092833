import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Mutation } from '@deity/falcon-data';

export const SET_NEWSLETTER_SUBSCRIBER = gql`
  mutation SetNewsletterSubscriber(
    $email: String!
    $firstname: String
    $lastname: String
    $remoteIp: String
    $recaptchaToken: String!
    $touchpoint: String
  ) {
    setNewsletterSubscriber(
      email: $email
      firstname: $firstname
      lastname: $lastname
      remoteIp: $remoteIp
      recaptchaToken: $recaptchaToken
      touchpoint: $touchpoint
    ) {
      success
      message
    }
  }
`;

export const CONFIRM_NEWSLETTER = gql`
  mutation ConfirmNewsletter($subscriberId: String, $confirmCode: String, $remoteIp: String) {
    confirmNewsletter(subscriberId: $subscriberId, confirmCode: $confirmCode, remoteIp: $remoteIp) {
      success
      message
      email
      subscriberInfo {
        newsletterId
        firstname
        lastname
        prefix
        dob
      }
    }
  }
`;

export const REMOVE_CUSTOMER_NEWSLETTER = gql`
  mutation RemoveCustomerNewsletter($remoteIp: String) {
    removeCustomerNewsletter(remoteIp: $remoteIp) {
      success
      message
    }
  }
`;

export const REMOVE_NEWSLETTER = gql`
  mutation RemoveNewsletter($remoteIp: String, $subscriberId: String, $customerId: String, $token: String) {
    removeNewsletter(remoteIp: $remoteIp, customerId: $customerId, subscriberId: $subscriberId, token: $token) {
      success
      message
    }
  }
`;

export const RESEND_CONFIRMATION_EMAIL = gql`
  mutation ResendConfirmationEmail($email: String!) {
    resendConfirmationEmail(email: $email) {
      success
      message
    }
  }
`;

export class SetNewsletterSubscriberMutation extends Mutation {
  static defaultProps = {
    mutation: SET_NEWSLETTER_SUBSCRIBER,
    awaitRefetchQueries: true,
    refetchQueries: ['NewsletterStatus', 'NewsletterSubscriptionSteps', 'NewsletterSubscriptionInfo']
  };
}

export class ResendConfirmationEmailMutation extends Mutation {
  static defaultProps = {
    mutation: RESEND_CONFIRMATION_EMAIL
  };
}

export class ConfirmNewsletterMutation extends Mutation {
  static defaultProps = {
    mutation: CONFIRM_NEWSLETTER,
    refetchQueries: ['NewsletterStatus', 'NewsletterSubscriptionSteps', 'NewsletterSubscriptionInfo']
  };
}

export class RemoveCustomerNewsletterMutation extends Mutation {
  static defaultProps = {
    mutation: REMOVE_CUSTOMER_NEWSLETTER,
    refetchQueries: ['NewsletterStatus', 'NewsletterSubscriptionSteps', 'NewsletterSubscriptionInfo']
  };
}

export class RemoveNewsletterMutation extends Mutation {
  static defaultProps = {
    mutation: REMOVE_NEWSLETTER,
    refetchQueries: ['NewsletterStatus', 'NewsletterSubscriptionSteps', 'NewsletterSubscriptionInfo']
  };
}

export const useConfirmNewsletterMutation = (options = {}) =>
  useMutation(CONFIRM_NEWSLETTER, {
    refetchQueries: ['NewsletterStatus', 'NewsletterSubscriptionSteps', 'NewsletterSubscriptionInfo'],
    ...options
  });

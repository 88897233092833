import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@deity/falcon-front-kit';
import { T, I18n } from '@deity/falcon-i18n';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { InnerHTML } from '@deity/falcon-ui-kit';
import { FormField } from '../../ui/Forms/FormField';
import { Button } from '../../ui/Button/Button';
import { useEmailValidatorMutation } from '../../helpers/EmailValidation/EmailValidationMutation';
import { getUserIp } from '../../elements/Geoip/Geoip';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { addRecaptchaJs, removeRecaptchaBadge } from '../../helpers/Recaptcha/ExternalJs';
import { NewsletterRegistrationTracking } from '../../elements/Tracking/GoogleTagManager';
import { useAddProductStockNotificationMutation } from '../../elements/Product/ProductNotifier/ProductNotifierMutation';
import { SetNewsletterSubscriberMutation } from './NewsletterMutation';
import styles from './Newsletter.module.scss';

export const NewsletterInput = withRouter(
  withStoreConfiguration(
    ({
      storeConfiguration,
      history,
      setSuccess,
      variant,
      legals,
      touchpoint,
      isNotifier,
      product,
      isDisabled = false,
      buttonSize,
      customer
    }) => {
      const [error, setError] = useState(null);
      const [emailValidator] = useEmailValidatorMutation();
      const [addProductToStock] = useAddProductStockNotificationMutation();

      const clearErrorMessage = () => {
        setTimeout(() => {
          setError(null);
        }, 3000);
      };

      const validate = values => {
        const errors = {};
        if (values.email && values.email !== '' && values.email.length > 80) {
          errors.email = <T id="formError.maxLength" maxLength="80" />;
        }
        return errors;
      };

      const additionalClasses = [];

      switch (variant) {
        case 'thin':
          additionalClasses.push(styles.NewsletterFormInputsThin);
          break;
        case 'widget':
          additionalClasses.push(styles.NewsletterFormInputsWidget);
          break;
        case 'notifier':
          additionalClasses.push(styles.NewsletterFormInputsNotifier);
          break;
        default:
          break;
      }

      return (
        <React.Fragment>
          <div className={styles.NewsletterForm}>
            <div className={[styles.NewsletterFormInputs, ...additionalClasses].join(' ')}>
              <SetNewsletterSubscriberMutation>
                {(setNewsletterSubscriber, { loading }) => (
                  <I18n>
                    {t => (
                      <Formik
                        initialValues={{
                          email: isNotifier ? customer.email : '',
                          firstname: '',
                          lastname: '',
                          dob: ''
                        }}
                        onSubmit={({ email, prefix, firstname, lastname }) => {
                          addRecaptchaJs(storeConfiguration, () => {
                            const { grecaptcha } = window;
                            emailValidator({
                              variables: { email }
                            })
                              .then(() => {
                                grecaptcha.ready(() => {
                                  grecaptcha
                                    .execute(storeConfiguration?.asam_recaptcha?.credentials?.site_key, {
                                      action: 'submit'
                                    })
                                    .then(recaptchaToken => {
                                      // Add your logic to submit to your backend server here.
                                      getUserIp().then(ipData => {
                                        const remoteIp = ipData.ip;

                                        setNewsletterSubscriber({
                                          variables: {
                                            email,
                                            prefix,
                                            firstname,
                                            lastname,
                                            remoteIp,
                                            recaptchaToken,
                                            touchpoint
                                          }
                                        }).then(({ data }) => {
                                          removeRecaptchaBadge();
                                          const response = data.setNewsletterSubscriber;
                                          if (response) {
                                            if (response.success) {
                                              if (setSuccess) {
                                                setSuccess(true);
                                                NewsletterRegistrationTracking({ storeConfiguration });
                                              }
                                              if (isNotifier) {
                                                addProductToStock({
                                                  variables: {
                                                    sku: product.sku
                                                  }
                                                })
                                                  .then(pData => {
                                                    console.log('sku worked', pData);
                                                  })
                                                  .catch(e => {
                                                    console.log('error', e);
                                                  });
                                              }
                                              setTimeout(history.push('/newsletter/not-confirmed'), 2000);
                                            }
                                            if (!response.success && response.message) {
                                              setError(response.message);
                                              clearErrorMessage();
                                            }
                                          }
                                        });
                                      });
                                    });
                                });
                              })
                              .catch(e => {
                                removeRecaptchaBadge();
                                if (e) {
                                  setError(t('emailValidator.error'));
                                  clearErrorMessage();
                                }
                              });
                          });
                        }}
                        validate={values => validate(values)}
                      >
                        {() => (
                          <Form id="newsletter" i18nId="newsletter" noValidate>
                            {variant === 'widget' && (
                              <>
                                <FormField
                                  name="firstname"
                                  label={t('addAddress.firstnameFieldLabel')}
                                  placeholder={t('addAddress.firstnameFieldPlaceholder')}
                                  variant="secondary"
                                  showErrorMessage
                                />
                                <FormField
                                  name="lastname"
                                  label={t('addAddress.lastnameFieldLabel')}
                                  placeholder={t('addAddress.lastnameFieldPlaceholder')}
                                  variant="secondary"
                                  showErrorMessage
                                />
                              </>
                            )}
                            <FormField
                              name="email"
                              type="email"
                              noSign
                              isNotifier
                              autoComplete="off"
                              removeStyles={variant !== 'widget'}
                              noMargin
                              showErrorMessage
                              variant={isNotifier ? 'notifier' : variant === 'widget' && 'secondary'}
                              label={
                                variant === 'thin' ? t('newsletter.emailFieldLabel') : t('newsletter.emailFieldLabel')
                              }
                              placeholder={
                                variant === 'thin'
                                  ? `${t('newsletter.placeholderThin')} *`
                                  : `${t('newsletter.placeholder')} *`
                              }
                              disabled={!!isNotifier}
                            />
                            {legals ? <InnerHTML className={styles.LegalHolder} html={legals} /> : null}
                            {error ? <p className={styles.NewsletterErrorMessage}>{error}</p> : null}
                            <Button
                              type="submit"
                              shadow={false}
                              loading={loading}
                              disabled={isDisabled}
                              size={buttonSize}
                            >
                              <T
                                id={!isNotifier ? 'newsletter.submit' : 'product.notifier.activateNotification.button'}
                              />
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </I18n>
                )}
              </SetNewsletterSubscriberMutation>
            </div>
          </div>
        </React.Fragment>
      );
    }
  )
);

NewsletterInput.propTypes = {
  /** set newsletter subscription success */
  setSuccess: PropTypes.func,
  /** set if newsletter input is thin */
  variant: PropTypes.oneOf(['thin', 'widget'])
};

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const ADD_PRODUCT_TO_STOCK = gql`
  mutation AddProductStockNotification($sku: String!) {
    addProductStockNotification(sku: $sku) {
      sku
      type
    }
  }
`;

export const useAddProductStockNotificationMutation = (options = {}) =>
  useMutation(ADD_PRODUCT_TO_STOCK, {
    ...options
  });

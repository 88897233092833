import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import StoreViewChecker from '../../helpers/StoreViewChecker/StoreViewChecker';
import { Button } from '../../ui/Button/Button';
import loadable from '../../../../../components/loadable';
import { GeoipQuery } from './GeoipQuery';
import styles from './Geoip.module.scss';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../ui/Modal/Modal'));

export const getUserIp = async () => {
  const response = await fetch('/middleware-rest/V1/get-ip');

  if (response) {
    return response
      .json()
      .then(data => {
        if (data) {
          return data;
        }
      })
      .catch(err => {
        return err;
      });
  }
};

const Geoip = withStoreConfiguration(({ storeConfiguration }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showDisapprovalText, setShowDisapprovalText] = useState(false);
  const [disablePopup, setDisablePopup] = useState(false);
  const [userIp, setUserIp] = useState(null);
  const moduleEnabled = storeConfiguration?.asam_geoip?.general?.enabled;
  let lang = '';
  let userAgent = '';

  // return null if SSR
  if (typeof window === 'undefined') {
    return null;
  }

  if (typeof navigator !== 'undefined' && 'languages' in navigator) {
    lang = navigator.languages.join(',');
    userAgent = navigator?.userAgent;
  }

  useEffect(() => {
    if (moduleEnabled === '1') {
      getUserIp().then(data => {
        // test ip CH: 151.236.26.140
        // test ip US: 8.8.8.8
        setUserIp(data.ip);
        setIsLoading(false);
      });
    }
  }, []);

  const disableRedirect = () => {
    setDisablePopup(true);
    Cookies.set('asam_disableRedirect', true);
  };

  const redirectSaved = Cookies.get('asam_disableRedirect');

  if (!redirectSaved) {
    const query = new URLSearchParams(useLocation().search);
    const trackingConsent = query.get('trackingConsent');

    if (trackingConsent === 'false') {
      return null;
    }

    return (
      <StoreViewChecker>
        {({ localeActiveStatus }) => {
          return (
            <React.Fragment>
              {!isLoading && (
                <React.Fragment>
                  <GeoipQuery variables={{ ip: userIp, browserLanguage: lang, userAgent }}>
                    {({ data: { geoip }, loading }) => {
                      // expected geoip statuses:
                      // 1 - show popup
                      // 2 - force redirect
                      // 3 - dont do anything
                      // 4 - force redirect, with no 'disable redirect' cookie
                      // false - module disabled
                      if (!loading) {
                        if (geoip.length) {
                          const activeStore = Object.keys(localeActiveStatus).find(
                            key => localeActiveStatus[key] === true
                          );
                          const result = JSON.parse(geoip);

                          if (result) {
                            Cookies.set('asam_geoip', result.detectedCountry);
                            setShowDisapprovalText(result.showDisapprovalText);
                            if (result.detectedCountry !== activeStore) {
                              if (result.status === 1) {
                                if (!disablePopup) {
                                  const popupContent = (
                                    <React.Fragment>
                                      <div className={styles.GeoipPopupTitle}>{result.popupHeadline}</div>
                                      <p className={styles.GeoipPopupDescription}>{result.popupDescription}</p>
                                      <div className={styles.GeoipButton}>
                                        <Button
                                          onClick={() => {
                                            if (showDisapprovalText) {
                                              Cookies.set('asam_disableRedirect', true);
                                            }
                                            window.location.href = result.redirectBaseUrl;
                                          }}
                                          href={result.redirectBaseUrl}
                                        >
                                          {result.approvalText}
                                        </Button>
                                      </div>
                                      {result.showDisapprovalText ? (
                                        <div className={styles.GeoipButtonCancel}>
                                          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                          <span
                                            onClick={() => {
                                              setShowPopup(false);
                                              disableRedirect();
                                            }}
                                            className="Link LinkPurple LinkLarge"
                                          >
                                            {result.disapprovalText}
                                          </span>
                                        </div>
                                      ) : null}
                                    </React.Fragment>
                                  );

                                  setShowPopup(true);

                                  return (
                                    <Modal
                                      content={popupContent}
                                      variant="centered"
                                      visible={showPopup && !disablePopup}
                                      hideClose={!showDisapprovalText}
                                      close={() => {
                                        if (showDisapprovalText) {
                                          setShowPopup(false);
                                          disableRedirect();
                                        }
                                      }}
                                    />
                                  );
                                }
                              }

                              if (result.status === 2) {
                                Cookies.set('asam_disableRedirect', true);
                                window.location.href = result.redirectBaseUrl;
                              }
                            } else {
                              Cookies.set('asam_disableRedirect', true);
                            }

                            if (result.status === 3) {
                              Cookies.set('asam_disableRedirect', true);
                            }
                            if (result.status === 4) {
                              window.location.href = result.redirectBaseUrl;
                            }
                          }

                          return <React.Fragment />;
                        }
                      }
                    }}
                  </GeoipQuery>
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }}
      </StoreViewChecker>
    );
  }
  return <React.Fragment />;
});

export default Geoip;

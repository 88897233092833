import React from 'react';
import { BackendConfigQuery } from '@deity/falcon-shop-data';

const StoreViewChecker = ({ children }) => (
  <BackendConfigQuery>
    {({
      data: {
        backendConfig: {
          activeLocale,
          shop: { stores }
        }
      }
    }) => {
      const storeLocale = [];
      const active = activeLocale.split('-')[1];

      stores.map(store => {
        const localeKey = store.locale.split('-')[1];
        storeLocale.push(localeKey);
        return storeLocale;
      });

      const localeActiveStatus = storeLocale.reduce((obj, key) => {
        let value = false;
        if (key === active) {
          value = !value;
        }
        return { ...obj, [key]: value };
      }, {});

      return children({ localeActiveStatus });
    }}
  </BackendConfigQuery>
);

export default StoreViewChecker;

import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_GEOIP_SUGESSTION = gql`
  query Geoip($ip: String, $browserLanguage: String, $userAgent: String) {
    geoip(ip: $ip, browserLanguage: $browserLanguage, userAgent: $userAgent)
  }
`;

export class GeoipQuery extends Query {
  static defaultProps = {
    query: GET_GEOIP_SUGESSTION,
    fetchPolicy: 'no-cache'
  };
}
